import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import BannerFour from "../components/BannerFour";
import BlogAreaFour from "../components/BlogAreaFour";
import BrandAreaOne from "../components/BrandAreaOne";
import CareerAreaOne from "../components/CareerAreaOne";
import ContactAreaFour from "../components/ContactAreaFour";
import CounterAreaFour from "../components/CounterAreaFour";
// import FaqAreaOne from "../components/FaqAreaOne";
import FaqAreaTwo from "../components/FaqAreaTwo";
// import FaqAreaThree from "../components/FaqAreaThree";
import FooterThree from "../components/FooterThree";
// import NavbarThree from "../components/NavbarThree";
import NavBarTwo from "../components/NavbarTwo";

import ProjectAreaOne from "../components/ProjectAreaOne";
import ServiceAreaGroupThree from "../components/ServiceAreaGroupThree";
// import TeamAreaTwo from "../components/TeamAreaTwo";
import TestimonialThree from "../components/TestimonialThree";
import Approach from "../components/Approch"
import Technologies from "../components/Technologies";
const HomeFour = () => {
  return (
    <>
      {/* Navigation Bar */}
    

      {/* Banner Four */}
      <BannerFour />

      {/* About Area Four */}
      <AboutAreaOne />

      {/* Service Area four */}
      <ServiceAreaGroupThree />

      {/* FAQ Area Two */}
      <FaqAreaTwo />
      <Approach/>
      <Technologies/>

      {/* Team Area Two */}
      {/* <TeamAreaTwo /> */}

      {/* Career Area One */}
      <CareerAreaOne />

      {/* Project Area One */}
      <ProjectAreaOne />

      {/* Contact Area Four */}
      <ContactAreaFour />

      {/* Testimonial Three */}
      <TestimonialThree />

      {/* Counter Area Four */}
      <CounterAreaFour />

      {/* Blog Area Four */}
      {/* <BlogAreaFour /> */}

      {/* Brand Area One */}
      <BrandAreaOne />

  
    </>
  );
};

export default HomeFour;
