import React, { useState } from 'react';
import { FaReact, FaAngular, FaWordpress, FaShopify, FaPhp, FaJava, FaPython, FaAws } from 'react-icons/fa';
import { SiNextdotjs, SiFlutter, SiMysql, SiMongodb, SiPostgresql, SiJavascript, SiTypescript, SiFirebase, SiMicrosoftazure } from 'react-icons/si';

const Technologies = () => {
  const [activeCategory, setActiveCategory] = useState('Web Platform');

  const categories = [
    'Web Platform',
    'Mobile Platform',
    'Databases',
    'Programming Languages',
    'Cloud Platforms'
  ];

  const technologies = {
    'Web Platform': [
      { name: 'React', icon: <FaReact /> },
      { name: 'Next.js', icon: <SiNextdotjs /> },
      { name: 'Angular', icon: <FaAngular /> },
      { name: 'WordPress', icon: <FaWordpress /> },
      { name: 'Shopify', icon: <FaShopify /> },
    ],
    'Mobile Platform': [
      { name: 'React Native', icon: <FaReact /> },
      { name: 'Flutter', icon: <SiFlutter /> },
      { name: 'Swift', icon: <FaJava /> },  // Assuming Swift as FaJava for demo, replace with relevant icon
      { name: 'Java', icon: <FaJava /> },
    ],
    'Databases': [
      { name: 'MySQL', icon: <SiMysql /> },
      { name: 'MongoDB', icon: <SiMongodb /> },
      { name: 'PostgreSQL', icon: <SiPostgresql /> },
    ],
    'Programming Languages': [
      { name: 'JavaScript', icon: <SiJavascript /> },
      { name: 'TypeScript', icon: <SiTypescript /> },
      { name: 'Python', icon: <FaPython /> },
      { name: 'PHP', icon: <FaPhp /> },
    ],
    'Cloud Platforms': [
      { name: 'Firebase', icon: <SiFirebase /> },
      { name: 'AWS', icon: <FaAws /> },
      { name: 'Azure', icon: <SiMicrosoftazure /> },
    ]
  };

  return (
    <div className="tech-section pd-top-120 pd-bottom-120">
      <div className="container">
        <div className="tech-section-title text-center">
          <h6 className="sub-title">OUR TECHNOLOGIES</h6>
          <h2 className="title">
            We Use <span>Technologies</span>
          </h2>
        </div>
        <div className="tech-categories-btn-group d-flex justify-content-center mt-4">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`tech-category-btn ${activeCategory === category ? 'active' : ''}`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="tech-icons-grid mt-5 d-flex justify-content-center flex-wrap">
          {technologies[activeCategory].map((tech, index) => (
            <div key={index} className="tech-icon-box text-center mx-3 my-2">
              <div className="tech-icon">{tech.icon}</div>
              <p className="tech-name">{tech.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technologies;
