import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import PricingAreaOne from '../components/PricingAreaOne';
import ServiceAreaGroupThree from '../components/ServiceAreaGroupThree';

const Service = () => {
  return (
    <>
      {/* Navigation Bar */}

      {/* Navigation Bar */}
      <Breadcrumb title={'Service'} />

      {/* ServiceAreaGroupThree */}
      <ServiceAreaGroupThree />



    </>
  );
};

export default Service;
