import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const CareerAreaOne = () => {
  return (
    <>
      {/* Career Area Start */}
      <div className="career-section career-section_1 pd-top-120 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  The Power Of <span>Software</span>, The Simplicity Of Solutions
                </h2>
              </div>
            </div>
          </div>
          <div className="career-wrap-area">
            <nav>
              <div
                className="nav nav-tabs career-nav-tab"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className="nav-link active"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="true"
                >
                  Mission
                </button>
                <button
                  className="nav-link"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >
                  Vision
                </button>
                <button
                  className="nav-link"
                  id="nav-career-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-career"
                  type="button"
                  role="tab"
                  aria-controls="nav-career"
                  aria-selected="false"
                >
                  Careers
                </button>
                <button
                  className="nav-link"
                  id="nav-solution-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-solution"
                  type="button"
                  role="tab"
                  aria-controls="nav-solution"
                  aria-selected="false"
                >
                  Solutions
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {/* Mission Tab */}
              <div
                className="tab-pane fade show active"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/16.png" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Our Mission</h3>
                      <p className="mb-3">
                        Our mission is to deliver innovative, scalable, and
                        user-friendly software solutions that empower businesses
                        to transform and thrive in the digital landscape. We aim
                        to simplify complex technological challenges with
                        high-quality development and exceptional customer
                        service.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Empower businesses
                            </li>
                            <li>
                              <FaCheckCircle /> Deliver scalable solutions
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Simplify complex problems
                            </li>
                            <li>
                              <FaCheckCircle /> Focus on customer satisfaction
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Vision Tab */}
              <div
                className="tab-pane fade"
                id="nav-vision"
                role="tabpanel"
                aria-labelledby="nav-vision-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/17.png" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Our Vision</h3>
                      <p className="mb-3">
                        Our vision is to become a global leader in software
                        development, driving innovation and delivering
                        exceptional value to businesses of all sizes. We
                        envision a future where technology is accessible and
                        beneficial for everyone.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Lead with innovation
                            </li>
                            <li>
                              <FaCheckCircle /> Create accessible technology
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Drive exceptional value
                            </li>
                            <li>
                              <FaCheckCircle /> Focus on global impact
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Careers Tab */}
              <div
                className="tab-pane fade"
                id="nav-career"
                role="tabpanel"
                aria-labelledby="nav-career-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/34.png" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Careers</h3>
                      <p className="mb-3">
                        Join our team of innovators and software engineers who
                        are passionate about making a difference. At [Your
                        Company Name], we offer a dynamic and inclusive work
                        environment with opportunities for professional growth
                        and development.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Growth opportunities
                            </li>
                            <li>
                              <FaCheckCircle /> Inclusive work environment
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle />Cutting-edge technology
                            </li>
                            <li>
                              <FaCheckCircle /> Competitive benefits
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Solutions Tab */}
              <div
                className="tab-pane fade"
                id="nav-solution"
                role="tabpanel"
                aria-labelledby="nav-solution-tab"
              >
                <div className="career-wrap">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src="assets/img/about/16.png" alt="img" />
                    </div>
                    <div className="col-lg-7">
                      <h3 className="mb-3">Our Solutions</h3>
                      <p className="mb-3">
                        We offer a range of software solutions, from mobile app
                        development and AI-powered applications to cloud
                        integration and custom software tailored to your
                        business needs. Our team is dedicated to delivering
                        high-quality, scalable, and secure solutions.
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Mobile App Development
                            </li>
                            <li>
                              <FaCheckCircle /> AI-Powered Applications
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="single-list-inner style-check style-heading style-check mb-3">
                            <li>
                              <FaCheckCircle /> Cloud Integration
                            </li>
                            <li>
                              <FaCheckCircle /> Custom Software Solutions
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Career Area End */}
    </>
  );
};

export default CareerAreaOne;
