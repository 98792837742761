import React from 'react';
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterThree = () => {
  return (
    <>
      {/* ================== Footer Start ==================*/}

      <footer className="footer-area bg-black mt-0 pd-top-100">
        <div className="container">
          <div className="row">
            {/* Company Info Section */}
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <Link to={'#home'} >
                  <img width={250} src="assets/img/logo-dark.png" alt="img" />
                  </Link>
                </div>
                <div className="details">
                  <p>
                    At Softiam, we provide cutting-edge digital solutions tailored to meet your business needs, ensuring you stay ahead in the digital world.
                  </p>
                  <ul className="social-media">
                    <li>
                      <Link to="#" aria-label="Facebook">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" aria-label="Twitter">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" aria-label="Instagram">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" aria-label="YouTube">
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Services Section */}
            <div className="col-xl-3 col-lg-4 offset-xl-2 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Services</h4>
                <ul>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Video Editing
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> PC Repairs
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <FaChevronRight /> Web Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Newsletter Section */}
            <div className="col-xl-3 col-lg-4 offset-xl-1 col-md-6">
              <div className="widget widget_about">
                <h4 className="widget-title">Subscribe to Our Newsletter</h4>
                <div className="details">
                  <p>
                    Stay updated with the latest trends and services from Softiam. Sign up for our newsletter!
                  </p>
                  <div className="subscribe mt-4">
                    <input type="email" placeholder="Enter your email" aria-label="Email" />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom bg-gray-black">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© Softiam 2024 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <Link to="#">Terms & Conditions</Link>
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer End ==================*/}

    </>
  );
};

export default FooterThree;
