import React from 'react';
import { FaStar } from 'react-icons/fa';
import Slider from 'react-slick';

const TestimonialItem = ({ shadowImg, mainImg, quoteImg, text, name, rating }) => {
  return (
    <div className="item">
      <div className="single-testimonial-inner style-3">
        <div className="row">
          <div className="col-md-5">
            <div className="thumb">
              <div className="shadow-img">
                <img src={shadowImg} alt="Shadow" />
              </div>
              <img className="main-img" src={mainImg} alt="Main" />
            </div>
          </div>
          <div className="col-md-7 align-self-center">
            <div className="details">
              <img className="quote" src={quoteImg} alt="Quote" />
              <p className="designation mb-0">{text}</p>
              <h6 className="mb-0 mt-3">{name}</h6>
              <div className="ratting-inner mt-3">
                {Array.from({ length: rating }, (_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const testimonials = [
  {
    shadowImg: 'assets/img/testimonial/0.png',
    mainImg: 'assets/img/testimonial/10.png',
    quoteImg: 'assets/img/testimonial/01.png',
    text: 'Softiam has been an absolute game-changer for our business. Their ability to understand our needs and deliver a high-quality, scalable solution exceeded our expectations. The team is professional, responsive, and committed to making sure everything works seamlessly. Highly recommend them for any software development project!',
    name: 'John D',
    rating: 5,
  },
  {
    shadowImg: 'assets/img/testimonial/0.png',
    mainImg: 'assets/img/testimonial/15.png',
    quoteImg: 'assets/img/testimonial/01.png',
    text: 'The team at Softiam really understands how to bring ideas to life. Their AI-powered solution for our customer support system has reduced response times and improved customer satisfaction immensely. The attention to detail and commitment to quality has been impressive from start to finish.',
    name: 'Devon Lane',
    rating: 5,
  },
  {
    shadowImg: 'assets/img/testimonial/0.png',
    mainImg: 'assets/img/testimonial/16.png',
    quoteImg: 'assets/img/testimonial/01.png',
    text: "Softiam helped us migrate our entire infrastructure to the cloud, and the results have been outstanding. Their expertise in cloud solutions is evident, and they ensured the transition was smooth and efficient. We're now more scalable and secure than ever before.",
    name: 'William Michael Harris',
    rating: 5,
  },
  {
    shadowImg: 'assets/img/testimonial/0.png',
    mainImg: 'assets/img/testimonial/17.png',
    quoteImg: 'assets/img/testimonial/01.png',
    text: 'The professionalism and skillset that Softiam brought to our project was remarkable. They developed a comprehensive e-commerce platform for us that was tailored to our specific needs. It’s user-friendly, efficient, and visually appealing. I would recommend Softiam to anyone in need of top-notch software development services.',
    name: 'Noah Matthew',
    rating: 5,
  },
];

const TestimonialThree = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/* =================== Testimonial Three Start ===================*/}
      <div
        className="testimonial-area testimonial-area_3 bg-cover pd-top-120 pd-bottom-120"
        style={{ backgroundImage: 'url("./assets/img/bg/13.png")' }}
      >
        <div className="container pd-bottom-100">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center">
                <h6 className="sub-title">Testimonial</h6>
                <h2 className="title">
                  What Client <span>Think</span> About Us?
                </h2>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-2 owl-carousel slider-control-dots">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <TestimonialItem key={index} {...testimonial} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* =================== Testimonial Three End ===================*/}
    </>
  );
};

export default TestimonialThree;
