import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const ServiceAreaGroupThree = () => {
  return (
    <>
      {/* service area start */}
      <div id="service" className="service-area bg-relative pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-4">
                <img src="assets/img/icon/17.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="mb-3">Web Development</h5>
                  <p className="content mb-3">
                    Our team builds responsive, scalable, and secure websites that provide an exceptional user experience. Whether it's a simple business site or a complex web application, we deliver robust web solutions that elevate your online presence.
                  </p>
                  <Link className="read-more-text" to="/service-details">
                    {/* Read More <FaArrowRight /> */}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-3">
                  <img src="assets/img/icon/16.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="mb-3">AI-Powered Applications</h5>
                  <p className="content mb-3">
                    Harness the power of artificial intelligence to streamline your operations, enhance decision-making, and deliver innovative user experiences. From AI chatbots to advanced data analytics, we bring your AI ideas to life.
                  </p>
                  <Link className="read-more-text" to="/service-details">
                    {/* Read More <FaArrowRight /> */}
                  </Link>
                </div>
              </div>
            </div>
             <div className="col-lg-4 col-md-6">
              <div className="single-work-process-inner">
                <div className="thumb mb-4">
                <img src="assets/img/icon/16.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="mb-3">Mobile App Development</h5>
                  <p className="content mb-3">
                    We create high-performance, user-friendly mobile applications tailored to your business needs. Our expertise spans both iOS and Android platforms, ensuring seamless experiences across devices.

                  </p>
                  <Link className="read-more-text" to="/service-details">
                    {/* Read More <FaArrowRight /> */}
                  </Link>
                </div>
              </div>
            </div>
         
           

          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceAreaGroupThree;
