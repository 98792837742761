import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}

      <div id="about" className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5"
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/about/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Empowering <span>Modern Businesses</span> With Cutting-Edge
                  Software Solutions
                </h2>
                <p className="content mb-4 mb-xl-5">
                  At softiam, we provide innovative software
                  solutions designed to help businesses thrive in today’s
                  digital landscape. Whether it's mobile app development, web
                  applications, or AI-powered systems, we deliver high-quality
                  solutions that meet our clients' unique needs.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/2.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Vision</h5>
                        <p>
                          To lead the software development industry by providing
                          businesses with cutting-edge technology that
                          accelerates growth and drives success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="assets/img/icon/3.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Our Mission</h5>
                        <p>
                          We are committed to delivering innovative software
                          solutions that solve real business challenges while
                          fostering long-term partnerships with our clients.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaOne End  ==================*/}

    </>
  );
};

export default AboutAreaOne;
