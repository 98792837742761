import React, { useState } from 'react';

const ApproachArea = () => {
  const [activeStep, setActiveStep] = useState(1);

  const approachSteps = [
    { 
      id: 1, 
      title: 'Discovery Phase', 
      description: 'We conduct thorough research to understand your business objectives, target audience, and market needs. This helps us craft a clear strategy that aligns with your goals.' 
    },
    { 
      id: 2, 
      title: 'Design & Development', 
      description: 'Our team designs user-centric solutions and develops them using the latest technologies. We ensure that the product is both functional and visually appealing.' 
    },
    { 
      id: 3, 
      title: 'Maintenance', 
      description: 'Post-launch, we provide continuous support and updates. This ensures your solution remains secure, scalable, and up-to-date with industry standards.' 
    },
    { 
      id: 4, 
      title: 'Deployment', 
      description: 'We handle the seamless launch of your product, making sure it is smoothly integrated into your environment and ready for users.' 
    },
    { 
      id: 5, 
      title: 'Testing & QA', 
      description: 'Our rigorous testing process ensures that your product meets the highest quality standards, both in terms of functionality and user experience.' 
    },
  ];
  

  return (
    <div className="approach-area pd-bottom-120">
      <div className="container">
        <div className="row pd-top-120">
          <div className="col-lg-6">
            <div className="section-title mb-0 mt-4 mt-lg-0">
              <h6 className="sub-title">WORKING PROCESS</h6>
              <h2 className="title">
                Our <span>Approach</span>
              </h2>
            </div>
            <div
              className="accordion accordion-inner style-2 accordion-icon-left mt-3"
              id="approachAccordion"
            >
              {approachSteps.map((step, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${step.id}`}>
                    <button
                      className={`accordion-button ${activeStep === step.id ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => setActiveStep(step.id)}
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${step.id}`}
                      aria-expanded={activeStep === step.id ? 'true' : 'false'}
                      aria-controls={`collapse${step.id}`}
                    >
                      {step.title}
                    </button>
                  </h2>
                  <div
                    id={`collapse${step.id}`}
                    className={`accordion-collapse collapse ${activeStep === step.id ? 'show' : ''}`}
                    aria-labelledby={`heading${step.id}`}
                    data-bs-parent="#approachAccordion"
                  >
                    <div className="accordion-body">{step.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="approach-visual">
              <ul className="list-unstyled process-diagram">
                {approachSteps.map((step, index) => (
                  <li
                    key={index}
                    className={`diagram-step text-center mb-4 ${activeStep === step.id ? 'active' : ''}`}
                    onClick={() => setActiveStep(step.id)}
                  >
                    <span className="diagram-title">{step.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproachArea;
