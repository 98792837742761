import {useState} from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';



export const projects = [
  {
    id: 1,
    category: "Health & Doctor",
    title: "Dentare - Dentist & Dental Clinic",
    link: "https://ngetemplates.com/dentare/",
image:"assets/img/gallery/1.png"
  },
  {
    id: 2,
    category: "Health & Doctor",
    title: "MediDove",
    link: "https://medidove-nextjs.vercel.app/",
image:"assets/img/gallery/2.png"
  },
  {
    id: 3,
    category: "Health & Doctor",
    title: "Donto - Dental Clinic",
    link: "https://donto-react.netlify.app/",
image:"assets/img/gallery/3.png"
  },
  {
    id: 4,
    category: "Restaurants",
    title: "Qichen - Food & Restaurant",
    link: "https://qichen-react.vercel.app/index-3",
image:"assets/img/gallery/4.png"
  },
  {
    id: 5,
    category: "Restaurants",
    title: "Kaffen - Restaurant & Cafe",
    link: "https://kaffen-reactjs.netlify.app/",
    image: "assets/img/gallery/5.png",
  },

  {
    id: 7,
    category: "Restaurants",
    title: "QuickEat - Restaurant Food Delivery",
    link: "https://quickeat-react.vercel.app/",
image:"assets/img/gallery/7.png"
  },
];


const ProjectAreaOne = () => {
  // Extract unique categories from the projects array
  const categories = ['All', ...new Set(projects.map((project) => project.category))];

  // State to manage active tab
  const [activeTab, setActiveTab] = useState('All');

  // Function to handle tab click
  const handleTabClick = (category) => {
    setActiveTab(category);
  };

  // Filter projects based on active tab
  const filteredProjects =
    activeTab === 'All'
      ? projects
      : projects.filter((project) => project.category === activeTab);

  return (
    <div id="projects" className="project-section project-section_1 pd-top-120">
      <div className="container">
        {/* Section Title */}
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center">
              <h6 className="sub-title">Gallery Here</h6>
              <h2 className="title">
                Latest <span>Project</span> Are Here
              </h2>
            </div>
          </div>
        </div>

        {/* Tabs Navigation */}
        <nav className="text-center">
          <div className="nav nav-tabs project-nav-tab d-inline-flex mb-5" role="tablist">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`nav-link ${activeTab === category ? 'active' : ''}`}
                onClick={() => handleTabClick(category)}
                type="button"
                role="tab"
                aria-selected={activeTab === category}
              >
                {category}
              </button>
            ))}
          </div>
        </nav>

        {/* Tab Content */}
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" role="tabpanel">
            <div className="row">
              {filteredProjects.map((project) => (
                <div key={project.id} className="col-md-4 mb-4">
                  <div className="single-project-inner">
                    <div className="image-wrapper">
                      <img src={project.image} alt={project.title} className="img-fluid" />
                    </div>
                    <div className="details">
                      <span>
                        <FaPlus />
                      </span>
                      <Link className="read-more-text" to={project.link} target="_blank" rel="noopener noreferrer">
                        View PROJECT
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
              {filteredProjects.length === 0 && (
                <div className="col-12">
                  <p className="text-center">No projects available in this category.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;

